<template>
    <div class="statistic">
        <div class="item" v-for="item in list" :key="item.title">
            <img class="bg" src="@/assets/sewage/data1.png" alt="" />
            <div>
                <div class="label">{{ item.title }}</div>
                <div class="data">
                    <span class="value">{{ item.value }}</span>
                    <span>{{ item.unit }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                title: '液位',
                value: 4.3,
                unit: 'm'
            }, {
                title: 'MLSS',
                value: 4.3
            }, {
                title: 'DO',
                value: 4.3,
                unit: 'm'
            }, {
                title: "PH",
                value: 4.3
            }, {
                title: '温度',
                value: 24.2,
                unit: '℃'
            }, {
                title: '曝气量',
                value: 8.9
            }, {
                title: "ORP",
                value: 4.3,
                unit: 'm'
            }]
        }
    }
}
</script>

<style lang="less" scoped>
.statistic {
    width: 50vw;
    padding: 0 5vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2vh;
    column-gap: 2vw;

    .item {
        position: relative;

        .bg {
            width: 100%;
        }

        &>div {
            position: absolute;
            top: 0;
            left: 40%;
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .label {
                color: #c8eaff;
                font-weight: 500;
                margin-bottom: 1vh;
            }

            .data {
                font-family: YouSheBiaoTiHei;
                color: #00ffff;
                background: linear-gradient(180deg, #89fffa 0%, #2ab8ec 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                .value {
                    font-size: 2em;
                    line-height: 1;
                }
            }
        }
    }
}
</style>