<template>
  <div>
    <div class="select">
      <div class="label">污水处理站</div>
      <div class="option">1#污水处理站</div>
      <div class="button">
        <img src="@/assets/sewage/button-bg.png" alt="" />
        <span>查询</span>
      </div>
    </div>

    <div class="cards">
      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />
        <div class="content">
          <img class="icon" src="@/assets/sewage/icon1.png" alt="" />
          <div>
            <div class="label">瞬时流量</div>
            <div class="data">
              <span class="value">134</span>
              <span>m³/h</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />
        <div class="content">
          <img class="icon" src="@/assets/sewage/icon2.png" alt="" />
          <div>
            <div class="label">当日累计流量</div>
            <div class="data">
              <span class="value">2,511</span>
              <span>h</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />

        <div class="content">
          <img class="icon" src="@/assets/sewage/icon3.png" alt="" />
          <div>
            <div class="label">设备总运行功率</div>
            <div class="data">
              <span class="value">18,723</span>
              <span>KW</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body">
      <Card title="生产指标">
        <Table />
      </Card>

      <Card title="运行指标">
        <Statistic />
      </Card>
    </div>
  </div>
</template>

<script>
import Table from './components/table.vue'
import Statistic from './components/statistic.vue'
export default {
  components: {
    Table,
    Statistic,
  }
};
</script>


<style lang="less" scoped>
.select {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;

  .label {

    font-size: 27px;
    font-family: YouSheBiaoTiHei;
    color: #00ffff;
    line-height: 35px;
    text-shadow: 0px 2px 14px rgba(31, 208, 255, 0.6);
  }

  .option {
    width: 466px;
    background: linear-gradient(360deg,
        rgba(34, 134, 246, 0.16) 0%,
        rgba(34, 134, 246, 0) 100%);
    border: 1px solid;
    border-image: linear-gradient(360deg,
        rgba(34, 134, 246, 1),
        rgba(34, 134, 246, 0.48)) 1 1;

    margin: 0 1em;
    padding: 0.4rem 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #7ba3c3;
    line-height: 30px;
  }

  .button {
    position: relative;

    img {
      height: 6vh;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 3vh;
      font-weight: 500;
    }
  }
}

.cards {
  display: flex;
  align-items: center;
  gap: 2vw;
  margin-bottom: 2vh;
  padding: 1vh 0;

  .card {
    flex: 1;
    position: relative;

    .bg {
      width: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      left: 10%;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      .icon {
        height: 6vh;
        margin-right: 2em;
      }

      .label {
        color: #c8eaff;
        font-weight: 500;
      }

      .data {
        font-family: YouSheBiaoTiHei;
        color: #00ffff;
        background: linear-gradient(180deg, #2cf39d 0%, #14eeef 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .value {
          font-size: 2em;
        }
      }
    }
  }
}

.body {
  display: flex;
  gap: 2vw;

  &>div {
    flex: 1;
    flex-shrink: 0;
  }




}
</style>